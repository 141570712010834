import Vue from 'vue'
import Router from 'vue-router'

const _import = file => () => import('../views/' + file + '.vue')

Vue.use(Router)

export const constantRouterMap = [
  { path: '/login', name: 'login', component: _import('login/index') },
  {
    path: '/',
    // 分别在各自环境变量中定义 productionLoacalTest:自测 productionTest:提测 production:上线
    // 注意npm run buildLocalTest 时候在.env.productionLocalTest根据自己的服务器端口调整配置
    redirect: '/searchWords',
    name: 'index',
    component: _import('layout/index'),
    children: [
 
     
      {
        path: '/screenshotPC',
        name: 'screenshotPC',
        component: _import('screenshotPC/index'),
        redirect: '/screenshotPC/index',
        children: [
          {
            path: 'index',
            name: 'screenshotPC',
            component: _import('screenshotPC/screenshotPC')
          }
        ]
      },
      {
        path: '/screenshotMobile',
        name: 'screenshotMobile',
        component: _import('screenshotMobile/index'),
        redirect: '/screenshotMobile/index',
        children: [
          {
            path: 'index',
            name: 'screenshotMobile',
            component: _import('screenshotMobile/screenshotMobile')
          }
        ]
      },
      {
        path: '/searchWords',
        name: 'searchWords',
        component: _import('searchWords/index'),
        redirect: '/searchWords/index',
        children: [
          {
            path: 'index',
            name: 'searchWords',
            component: _import('searchWords/searchWords')
          }
        ]
      }
    ]
  },
  
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: '/smartTool/',
  routes: constantRouterMap
})
