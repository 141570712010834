import Vue from 'vue'
import _ from 'lodash'
import Cookies from 'js-cookie'
import axios from 'axios'

/**
 * 重写数组flat方法针对不兼容flat的浏览器
 */
Array.prototype.myflat = function (depth = 1) {
  return this.reduce((pre, cur) => {
    if (Array.isArray(cur)) {
      // 这里应该每次reduce都产生一个新的depth
      let _depth = depth
      if (_depth > 0) {
        _depth--
        pre = [...pre, ...cur.myflat(_depth)]
      } else { // depth = 0控制结束递归
        if (cur.length !== 0) { // 空数组抛弃！
          pre.push(cur)
        }
      }
    } else {
      pre.push(cur)
    }
    return pre
  }, [])
}

/**
 * 全局fileter
 */
// 转化为千分位
Vue.filter('toThousands', function (num) {
  const strArr = (num + '').split('.')
  strArr[0] = strArr[0].replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
    return match + ','
  })
  return strArr.join('.')
})
// 保留整数
Vue.filter('s0', function (num = 0) {
  return num.toFixed(0)
})
// 保留两位小数
Vue.filter('s2', function (num = 0) {
  if (!(num + '').includes('.')) return num.toFixed(0)
  return num.toFixed(2)
})

export function getQuery (str) {
  const reg = /([^?&=]+)=([^?&=]+)/g
  const obj = {}
  str.replace(reg, function () {
    obj[arguments[1]] = arguments[2]
  })
  return obj
}

/**
 * 判断上一次登陆是否从其他平台过来的
 * 直接刷新，在网址栏输入网址，document.referrer都为空
 */
export function lastLoginFromOtherPlatform () {
  if (Cookies.get('jumpFromOtherPlatform') === 'true') {
    if (process.env.VUE_APP_BASE_TRUE_ENV === 'true') {
      Cookies.set('jumpFromOtherPlatform', false, { domain: 'cue.group' })
    } else {
      Cookies.set('jumpFromOtherPlatform', false)
    }
    return true
  }
  return false
}

/**
 * 是否有cookie
 * 判断本轮操作是否从聚合平台进入，从聚合平台过来携带cookie,从本平台登陆置空cookie
 */
export function hasLoginUserCookies () {
  return !_.isEmpty(Cookies.getJSON('loginUser'))
}

/**
 * 封装了一个根请求流文件的方法，避免chrome两次下载或打开新页签
 */
export function getDownloadFile (downloadurl, callback) {
  if (Array.isArray(downloadurl)) { // 兼容部分接口返回的是一个地址数组
    downloadurl = downloadurl[0]
  }
  let index = downloadurl.lastIndexOf(',')
  let name = downloadurl.slice(index + 1)
  // 如果是线索通下载，文件名读不出name,写死，理论上可以从responseHeader的content-disposition读取
  if (name.includes('%22')) {
    name = '线索通操作'
  }
  axios({
    method: 'GET',
    url: downloadurl,
    responseType: 'blob'
  }).then(res => {
    // 将二进制数据流转成URL对象
    const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    callback && callback()
  })
}
