import _ from 'lodash'
import router from '../router'
import store from '../store'
import Cookies from 'js-cookie'
import { lastLoginFromOtherPlatform } from '../../../utils/util'

router.beforeEach((to, from, next) => {
  const whiteList = ['/login', '/chooseClient']
  initLoginUserOnce()// 最开始路由导航的时候的时候初始化一遍用户信息，然后就不要执行这个函数了
  if (_.isEmpty(store.state.auth.loginUser) && !whiteList.includes(to.path)) {
    router.push('/login')
  }
  next()
})

const initLoginUserOnce = _.once(initLoginUser)

function initLoginUser () {
  if (lastLoginFromOtherPlatform()) { // 第二个条件对应的是从新打开一个新标签，自动初始化数据
    if (Cookies.get('loginUser')) { // 但是有cookie信息,先初始化一下用户信息
      store.dispatch('initLoginUser') // 先从cookie里面恢复一下数据
      console.log('初始化用户数据！')
    }
  }
}
