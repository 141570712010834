import Cookies from 'js-cookie'
const auth = {
  state: {
    loginUser: {},
    token: ''
  },
  mutations: {
    // 登陆用户的各种信息,包括所选客户
    change_loginUser (state, loginUser) {
      state.loginUser = { ...state.loginUser, ...loginUser }
    },
    change_token (state, token) {
      state.token = token
    }
  },
  actions: {
    initLoginUser ({ commit }) {
      const loginUser = Cookies.getJSON('loginUser')
      commit('change_loginUser', {
        ...loginUser
      })
      commit('change_token', loginUser.utoken)
    }
  }
}

export default auth
